export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'User/Buyer',
    icon: 'UsersIcon',
    children: [
      {
        title: 'List User',
        route: 'users.index',
        permission: 'show users',
      },
      {
        title: 'Referral',
        route: 'referral.index',
        permission: 'show users',
      },
      {
        title: 'Pital Pay',
        children: [
          {
            title: 'Transactions',
            route: 'pay.index',
            permission: 'show wallet transaction',
          },
          {
            title: 'Topup List',
            route: 'topup.index',
            permission: 'show topup',
          },
          {
            title: 'Withdraw List',
            route: 'withdraw.index',
            permission: 'show withdraw',
          },
        ],
      },
      {
        title: 'Pital Coin',
        children: [
          {
            title: 'Transactions',
            route: 'coin.index',
            permission: 'show wallet transaction',
          },
        ],
      },
    ],
  },
  {
    title: 'Talenta',
    icon: 'UsersIcon',
    children: [
      {
        title: 'List Talenta',
        route: 'talenta.index',
        permission: 'show talent',
      },
      {
        title: 'Talenta Jagoan',
        children: [
          {
            title: 'Paket Talenta Jagoan',
            route: 'packageTalentaJagoan.index',
            permission: 'show paket talenta jagoan',
          },
          {
            title: 'Order Paket Talenta Jagoan',
            route: 'orderPackageTalentaJagoan.index',
            permission: 'show order paket talenta jagoan',
          },
        ],
      },
      {
        title: 'Pital Poin',
        children: [
          {
            title: 'Transactions',
            route: 'talenta.poin.index',
            permission: 'show wallet transaction',
          },
          {
            title: 'Topup List',
            route: 'talenta.topup-poin.index',
            permission: 'show topup',
          },
        ],
      },
      {
        title: 'Pital Ads',
        children: [
          {
            title: 'Transactions',
            route: 'talenta.ads.index',
            permission: 'show wallet transaction',
          },
          {
            title: 'Topup List',
            route: 'talenta.topup-ads.index',
            permission: 'show topup',
          },
          {
            title: 'Campaign',
            route: 'campaign.index',
            permission: 'show campaign',
          },
        ],
      },
      {
        title: 'Dompet Usaha',
        children: [
          {
            title: 'Transactions',
            route: 'talenta.business.index',
            permission: 'show wallet transaction',
          },
          {
            title: 'Withdraw List',
            route: 'talenta.withdraw-dompet.index',
            permission: 'show withdraw',
          },
        ],
      },
    ],
  },
  {
    title: 'Marketplace',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Daftar Jasa',
        route: 'jasa.index',
        permission: 'show jasa',
      },
      {
        title: 'Daftar Lowongan',
        route: 'vacancy.index',
        permission: 'show lowongan',
      },
      {
        title: 'Kategori',
        route: 'category.index',
        permission: 'show category',
      },
      {
        title: 'Kupon',
        route: 'coupon.index',
        permission: 'show kupon',
      },
      {
        title: 'Gift Voucher Design',
        route: 'gift-voucher-design.index',
        permission: 'list design voucher',
      },
    ],
  },
  {
    title: 'Order',
    icon: 'BoxIcon',
    children: [
      {
        title: 'List Order',
        route: 'order.index',
        permission: 'list order',
      },
      {
        title: 'Complain',
        route: 'complaint.index',
        permission: 'list komplain',
      },
      {
        title: 'Misi',
        route: 'missions.index',
        permission: 'list misi',
      },
      {
        title: 'Tiket Undian',
        route: 'ticket.index',
        permission: 'list tiket undian',
      },
      {
        title: 'Gift Voucher Order',
        route: 'history-order.index',
        permission: 'order gift voucher',
      },
    ],
  },
  {
    title: 'Pembayaran',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'List Pembayaran',
        route: 'payment.index',
        permission: 'list payment',
      },
      {
        title: 'Rekening Pembayaran',
        route: 'bank.index',
        permission: 'show bank',
      },
    ],
  },
  {
    title: 'Admin User',
    icon: 'KeyIcon',
    children: [
      {
        title: 'Admin Management',
        route: 'admin.index',
        permission: 'show admins',
      },
      {
        title: 'Role Management',
        route: 'role.index',
        permission: 'roles and permission management',
      },
    ],
  },
  {
    title: 'Content',
    icon: 'ImageIcon',
    children: [
      {
        title: 'Slider',
        route: 'bannerslider.index',
        permission: 'show slider',
      },
      {
        title: 'Static Image',
        route: 'staticImage.index',
        permission: 'show page/image',
      },
      {
        title: 'Static Page',
        route: 'staticPage.index',
        permission: 'show page/image',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'setting.index',
    permission: 'list settings',
  },
]
